<template>
  <div class="container">
    <h1>{{ postId ? 'Update Post' : 'Create Post'}}</h1>
    <div class="row pt-3 pb-1">
      <div class="col-12">
        <div class="d-flex card">
          <label for="image_upload">
            <div class="cover-image" :style="imageStyle"></div>
          </label>
          <input id="image_upload" type="file" class="form-control d-none" @change="onImageSelect">
        </div>
      </div>
    </div>

    <div class="row pt-1 pb-1">
      <div class="col-4">
        <select v-model="selectedCategory" class="form-control">
          <option disabled selected :value="null">Pick the category</option>
          <option v-for="category in categories" :value="category.name">{{ category.name }}</option>
        </select>
      </div>
      <div class="col-8">
        <input v-model="title" class="form-control" type="text" placeholder="Title here...">
      </div>
    </div>

    <div class="row pt-1 pb-1">
      <div class="col-12">
        <input v-model="description" class="form-control" type="text" placeholder="Short description here...">
      </div>
    </div>

    <div class="row pt-1 pb-1">
      <div class="col-12">
        <div class="form-group" v-for="(component, index) in postComponents">
          <button @click="removeComponent(index)" class="btn btn-danger">X</button>
          <section-heading
            v-if="component.type === consti.postComponents.sectionHeading"
            :component-data="component"
            :key="makeRandomID(9) + component.toString()"></section-heading>
          <paragraph
            v-else-if="component.type === consti.postComponents.paragraph"
            :component-data="component"
            :key="makeRandomID(9) + component.toString()"></paragraph>
          <code-snippet
            v-else-if="component.type === consti.postComponents.code"
            :component-data="component"
            :key="makeRandomID(9) + component.toString()"></code-snippet>
          <image-carousel
            v-else-if="component.type === consti.postComponents.image"
            :component-data="component"
            :key="makeRandomID(9) + component.toString()"></image-carousel>
        </div>
      </div>
    </div>

    <div class="row pt-1 pb-1">
      <div class="col-4"></div>
      <div class="col-4">
        <add-section-button @sectionSelected="addComponent"></add-section-button>
        <button @click="state.getPostId() ? update() : save()" type="button" class="btn btn-success"><span v-if="loading" class="spinner-border spinner-border-sm"></span>
        {{ saveButtonText }}</button>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>

<script>
  import AddSectionButton from '@/components/AddSectionButton'
  import consti from '@/const'
  import Paragraph from '../components/Paragraph'
  import SectionHeading from '../components/SectionHeading'
  import CodeSnippet from '../components/CodeSnippet'
  import request from '@/request'
  import { useState } from '@/store/store'
  import ImageCarousel from '@/components/ImageCarousel'

  export default {
    name: 'CreatePost',
    components: { ImageCarousel, CodeSnippet, SectionHeading, Paragraph, AddSectionButton },
    created () {
      request.get(consti.baseUrl + consti.categories, this.$router)
        .then(data => {
          this.loading = false
          this.categories = data

          if (this.postId) {
            this.state.setPostId(this.postId)
            request.get(consti.baseUrl + consti.posts + '/' + this.postId, this.$router)
              .then(data => {
                this.loading = false
                this.postComponents = data.content
                this.title = data.title
                this.selectedCategory = data.category.name
                this.coverImageUrl = data.cover_image
                this.description = data.description
              }, error => {
                this.loading = false
              })
          }
        }, error => {
          this.loading = false
        })
    },
    setup() {
      return {
        state: useState()
      }
    },
    computed: {
      imageStyle() {
        return {
          'background': 'url("' + this.coverImageUrl + '")'
        }
      },
      saveButtonText() {
        return this.loading ? 'Saving' : 'Save'
      }
    },
    data() {
      return {
        loading: false,
        consti: consti,
        postComponents: [],
        categories: [],
        title: null,
        description: null,
        coverImage: null,
        coverImageUrl: require('../assets/default-blur.jpg'),
        selectedCategory: null,
        postId: this.$route.params.post
      }
    },
    methods: {
      addComponent(type) {
        switch (type) {
          case consti.postComponents.paragraph:
            this.postComponents.push({
              type: type,
              pType: null,
              text: null
            })
            break
          case consti.postComponents.sectionHeading:
            this.postComponents.push({
              type: type,
              id: null,
              text: null
            })
            break
          case consti.postComponents.code:
            this.postComponents.push({
              type: type,
              language: null,
              code: null
            })
            break
          case consti.postComponents.image:
            this.postComponents.push({
              type: type,
              images: []
            })
            break
          default:
            break
        }
      },
      removeComponent(index) {
        this.postComponents.splice(index, 1)
      },
      makeRandomID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; ++i) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
      },
      save() {

        this.loading = true

        let formData = new FormData()

        formData.append('title', this.title)
        formData.append('category', this.selectedCategory)
        formData.append('description', this.description)
        if (!!this.coverImage) {
          formData.append('cover_image', this.coverImage)
        }
        formData.append('content', JSON.stringify(this.formatData()))

        request.submitFormData(consti.baseUrl + consti.posts, formData, {
          'Content-Type': 'multipart/form-data'
        }, this.$router)
          .then(data => {
            this.loading = false
            this.state.setPostId(data.id)
          }, error => {
            this.loading = false
          })
      },
      update() {

        this.loading = true

        let formData = new FormData()

        formData.append('title', this.title)
        formData.append('category', this.selectedCategory)
        formData.append('description', this.description)
        if (!!this.coverImage) {
          formData.append('cover_image', this.coverImage)
        }
        formData.append('content', JSON.stringify(this.formatData()))
        formData.append('_method', 'PUT')

        request.updateFormData(consti.baseUrl + consti.posts + '/' + this.state.getPostId(), formData, {
          'Content-Type': 'multipart/form-data'
        }, this.$router)
          .then(data => {
            this.loading = false
          }, error => {
            this.loading = false
          })
      },
      formatData() {
        let formattedData = []

        this.postComponents.forEach((item, index) => {
          item.index = index
          formattedData.push(item)
        })

        return formattedData
      },
      onImageSelect(e) {
        const file = e.target.files[0];
        this.coverImageUrl = URL.createObjectURL(file);
        this.coverImage = file;
      }
    },
    beforeUnmount() {
      this.state.setPostId(null)
    },
    mounted() {
      document.title = 'BMS | Create Post'
    }
  }
</script>

<style scoped>
  .cover-image {
    background-position: center;
    background-size: cover !important;
    height: 300px;
    cursor: pointer;
  }

  .card {
    border: none;
  }
</style>
